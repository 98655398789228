<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div>
          <b-card-group deck>
            <b-card header-tag="header" footer-tag="footer">
              <div class="col-lg-3 mb-lg-0 mb-6">
                <label>Tệp tải lên:</label>
                <input
                    type="file"
                    class="form-control"
                    placeholder="Chọn file"
                    @change="onFileChange"
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                />
                <p class="text-muted">Hỗ trợ định dạng (.xlsx) <a :href="urlDownloadFile">Tải tệp mẫu</a></p>
                <button
                    class="btn btn-primary btn-primary--icon mt-2"
                    v-if="hasPermission('cardImport')"
                    @click="importCard()"
                >
                  Lưu
                </button>
              </div>
            </b-card>
          </b-card-group>
        </div>
      </div>
    </div>
  </div>
</template>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<script>
import RepositoryFactory from '@/core/repositories/repositoryFactory';
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Common from "@/core/mixins/common";
import permissionMiddleware from "@/core/mixins/permissionMiddleware";

const CardStoreRepository = RepositoryFactory.get('cardStore');

export default {
  computed: {
  },
  mixins: [Common],
  data() {
    return {
      formInput: {
        file: null,
      },
      urlDownloadFile: process.env.VUE_APP_API_URL + 'api/card-store/download-template',
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Nhập thẻ', route: 'import' }
    ]);
  },
  methods: {
    onFileChange(event) {
      this.formInput.file = event.target.files[0];
    },
    importCard() {
      if (!this.formInput.file) {
        this.notifyAlert("warn", "Vui lòng chọn tệp trước khi thực hiện import.");
        return;
      }

      const formData = new FormData();
      formData.append('file', this.formInput.file);

      this.$bus.$emit("show-loading", true);
      try {
        CardStoreRepository.importCard(formData)
            .then((response) => {
              if (response.data.error_code) {
                this.notifyAlert("warn", response.data.message);
              } else {
                this.notifyAlert(
                    "success",
                    "Nhập thẻ thành công"
                );
                this.$bus.$emit("show-loading", false);
                return this.$router.push({ name: "cardList" });
              }
            })
            .catch(() => {
              this.notifyAlert("warn", "Có lỗi xảy ra");
            });
      } catch (e) {
        this.$bus.$emit('show-loading', false);
      }
    },
    hasPermission(toName) {
      return permissionMiddleware(toName);
    },
  }
};
</script>
